

import { getCdnUrl, getResizedImgUrl } from '~/scriptData/common/interM2'

export default {
  name: 'CommonImage',
  props: {
    alt: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    imageClass: {
      type: String,
      default: ''
    },
    // resize는 '398x497' 와 같이 String 형식으로 들어올 수도 있음
    resize: {
      type: [String, Number],
      default: null
    },
    quality: {
      type: Number,
      default: null
    },
    format: {
      type: String,
      default: ''
    },
    error: {
      type: [String || Function],
      default: null
    },
    useLazyLoad: {
      type: Boolean,
      default: true
    },
    isStaticImage: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      tryOriginalTag: false,
    }
  },
  computed: {
    avifWithWebpSrc () {
      return getCdnUrl(getResizedImgUrl(this.src, this.resize, this.quality, 'avifWithWebp'))
    },
    resizedSrc () {
      return getCdnUrl(getResizedImgUrl(this.src, this.resize, this.quality, ''))
    },
    staticImageSrc () {
      if (this.isErrorHandled) {
        return this.error ?? '/images/tempLy/noimage.png'
      } else {
        return this.src
      }
    },
    useFallback () {
      return process.env.USE_MEDIA_FALLBACK === 'true'
    },
    isPlanA () { // fallback을 사용하도록 설정했고, 실제로 fallback을 사용해도 될 때
      return this.useFallback && !this.tryOriginalTag
    },
    isPlanB () { // fallback을 사용하지 않도록 설정했거나, fallback을 사용하도록 설정했는데 에러가 났을 경우
      return !this.useFallback || (this.useFallback && this.tryOriginalTag)
    }
  },
  methods: {
    load () {
      this.$emit('load')
    },
    errorFallbackImage (e) {
      this.tryOriginalTag = true
    },
    errorImage (e) {
      if (!this.isErrorHandled) {
        this.isErrorHandled = true
        e.target.src = this.error ?? '/images/tempLy/noimage.png'
      }
    }
  }
}
